export interface FeatureFlags {
  enableCashManagementCategories: boolean;
  enableCashFlowManagementV2: boolean;
  enableCashFlowMultiWallet: boolean;
  enableCompanyAddressSelfChange: boolean;
  enableEnhancedBirthCityInput: boolean;
  enableInternationalTransfer: boolean;
  enableMandateeOnboarding: boolean;
  enableMultipleTransactionDocs: boolean;
  enableNewLandbotFlow: boolean;
  enableNewPsp: boolean;
  enableSiretWall: boolean;
  enableSwiftTransfer: boolean;
  enableNewAccountingExport: boolean;
  enableNewInsurance: boolean;
  enableSasuActivities: boolean;
  enableSasuCapital: boolean;
  enableInvoicingTradeNameOption: boolean;
  enableSecurityWithStrongAuthentication: boolean;
  enabledNewPhysicalOrder: boolean;
}

export const ALL_FEATURE_FLAGS = {
  // When working on your local machine
  development: {
    enableCashFlowManagementV2: true,
    enableCashFlowMultiWallet: true,
    enableCashManagementCategories: true,
    enableCompanyAddressSelfChange: true,
    enabledNewPhysicalOrder: false,
    enableEnhancedBirthCityInput: true,
    enableInternationalTransfer: true,
    enableInvoicingTradeNameOption: true,
    enableMandateeOnboarding: true,
    enableMultipleTransactionDocs: false,
    enableNewAccountingExport: false,
    enableNewInsurance: false,
    enableNewLandbotFlow: true,
    enableNewPsp: true,
    enableSasuActivities: true,
    enableSasuCapital: true,
    enableSecurityWithStrongAuthentication: false,
    enableSiretWall: true,
    enableSwiftTransfer: true,
  } satisfies FeatureFlags,
  // In production
  production: {
    enableCashFlowManagementV2: true,
    enableCashFlowMultiWallet: true,
    enableCashManagementCategories: true,
    enableCompanyAddressSelfChange: false,
    enabledNewPhysicalOrder: false,
    enableEnhancedBirthCityInput: true,
    enableInternationalTransfer: false,
    enableInvoicingTradeNameOption: true,
    enableMandateeOnboarding: true,
    enableMultipleTransactionDocs: false,
    enableNewAccountingExport: false,
    enableNewInsurance: true,
    enableNewLandbotFlow: true,
    enableNewPsp: false,
    enableSasuActivities: true,
    enableSasuCapital: true,
    enableSecurityWithStrongAuthentication: false,
    enableSiretWall: true,
    enableSwiftTransfer: true,
  } satisfies FeatureFlags,
  // In staging
  staging: {
    enableCashFlowManagementV2: true,
    enableCashFlowMultiWallet: true,
    enableCashManagementCategories: true,
    enableCompanyAddressSelfChange: true,
    enabledNewPhysicalOrder: false,
    enableEnhancedBirthCityInput: true,
    enableInternationalTransfer: false,
    enableInvoicingTradeNameOption: true,
    enableMandateeOnboarding: true,
    enableMultipleTransactionDocs: false,
    enableNewAccountingExport: false,
    enableNewInsurance: true,
    enableNewLandbotFlow: true,
    enableNewPsp: true,
    enableSasuActivities: true,
    enableSasuCapital: true,
    enableSecurityWithStrongAuthentication: false,
    enableSiretWall: true,
    enableSwiftTransfer: true,
  } satisfies FeatureFlags,
  // When running automated tests
  testing: {
    enableCashFlowManagementV2: true,
    enableCashFlowMultiWallet: true,
    enableCashManagementCategories: true,
    enableCompanyAddressSelfChange: true,
    enabledNewPhysicalOrder: false,
    enableEnhancedBirthCityInput: true,
    enableInternationalTransfer: false,
    enableInvoicingTradeNameOption: true,
    enableMandateeOnboarding: true,
    enableMultipleTransactionDocs: false,
    enableNewAccountingExport: false,
    enableNewInsurance: true,
    enableNewLandbotFlow: true,
    enableNewPsp: false,
    enableSasuActivities: true,
    enableSasuCapital: true,
    enableSecurityWithStrongAuthentication: false,
    enableSiretWall: true,
    enableSwiftTransfer: true,
  } satisfies FeatureFlags,
};

export type FeatureFlagsEnv = keyof typeof ALL_FEATURE_FLAGS;
export type FeatureFlagsKey = keyof FeatureFlags;

const getFeatureFlags = (): FeatureFlags => {
  switch (import.meta.env.VITE_FEATURES_ENV) {
    case 'production':
      return ALL_FEATURE_FLAGS.production;
    case 'staging':
      return ALL_FEATURE_FLAGS.staging;
    case 'testing':
      return ALL_FEATURE_FLAGS.testing;
    default:
      return ALL_FEATURE_FLAGS.development;
  }
};

const featureFlags = getFeatureFlags();

export default featureFlags;
