import LocalizedStrings from 'localized-strings';

const zodLocales = new LocalizedStrings({
  en: {
    errors: {
      alphanumeric: 'Only alphanumeric characters are allowed',
      alphanumericAndAccented: 'Special characters are not allowed',
      amount: 'This amount is not valid',
      integer: 'Please enter an integer',
      invalidEmail: 'This email seems invalid',
      invalidIban: 'This IBAN seems invalid',
      invalidNumber: 'Only numbers are allowed',
      invalidSiren: 'This SIREN is not valid',
      invalidSirenOrSiret: 'This SIREN/SIRET is not valid',
      invalidSsn:
        'The number is incorrect. Enter the 13 digits and the 2-digit key shown on your carte vitale.',
      invalidSwiftBic: 'This BIC seems invalid',
      invalidZipCode: 'This zip code seems invalid',
      maxCharacters: 'This field must contain less than {0} characters',
      maxDecimals: 'Max {0} decimals',
      maxValue: 'Max value: {0}',
      nonZeroAmount: 'The amount cannot be zero',
      positive: 'This number should be positive',
      required: 'This field is required',
      selectionRequired: 'Please select an option',
    },
  },
  fr: {
    errors: {
      alphanumeric: 'Seuls les caractères alphanumériques sont autorisés',
      alphanumericAndAccented: 'Les caractères spéciaux sont interdits',
      amount: 'Ce montant n’est pas valide',
      integer: 'Veuillez entrer un nombre entier',
      invalidEmail: 'Cette adresse e-mail semble invalide',
      invalidIban: 'Cet IBAN semble invalide',
      invalidNumber: 'Seuls les chiffres sont autorisés',
      invalidSiren: 'Ce SIREN est invalide',
      invalidSirenOrSiret: 'Ce SIREN/SIRET est invalide',
      invalidSsn:
        'Le numéro est incorrect. Saisissez les 13 chiffres et la clé de 2 chiffres inscrits sur votre carte vitale.',
      invalidSwiftBic: 'Ce BIC semble invalide',
      invalidZipCode: 'Ce code postal semble invalide',
      maxCharacters: 'Ce champ doit contenir moins de {0} caractères',
      maxDecimals: 'Max. {0} décimales',
      maxValue: 'Valeur max. : {0}',
      nonZeroAmount: 'Le montant ne peut pas être nul',
      positive: 'Ce nombre doit être positif',
      required: 'Ce champ est requis',
      selectionRequired: "Veuillez sélectionner l'une de ces options",
    },
  },
} as const);

export default zodLocales;
